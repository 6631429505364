import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Row, Col, Button, Tabs, Select, Spin, Tag } from "antd";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { MLB_CSV_HEADER_FD, MLB_CSV_HEADER_DK, NFL_CSV_HEADER_FD, NFL_CSV_HEADER_DK } from "../csv";
import Filters from "../Controls/Filters";
import Teams from "../table/Teams";
import BaseOptimizer from "./BaseOptimizer";
import Rosters from "../table/Rosters";
import Players from "../table/Players";
// import ProjTable from "../table/ProjTable";
import PersistentProj from "../table/PersistentProj";
import DataVis from "../datavis/DataVis";
import PlayerOrSpin from "../table/PlayersOrSpin";
import ExposureReport from "../LineupMetadata/ExposureReport";
import Aggregate from "./Aggregate";
import "../App.css";

import "antd/dist/antd.css";

const TabPane = Tabs.TabPane;
const Option = Select.Option;

// https://stackoverflow.com/questions/24964035/how-to-export-javascript-array-info-to-csv-on-client-side
const altCSVConstruction = csv => {
  window.heap.track("CSV Download");

  let csvContent = "data:text/csv;charset=utf-8,";
  csv.forEach(function(rowArray) {
    let row = rowArray.join(",");
    csvContent += row + "\r\n";
  });
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "draftfast-dk.csv");
  link.innerHTML = "Click Here to download";
  document.body.appendChild(link); // Required for FF

  link.click(); // This will download the data file named "my_data.csv".
};

class Optimizer extends BaseOptimizer {
  trackCSVDownload = () => {
    console.log("downloading csv");
    window.heap.track("CSV Download");
  };

  getCSVDownloadButton = csv => {
    const fanDuel = (
      <CSVLink style={{ marginLeft: "11px" }} data={csv} filename="draftfast-fd.csv">
        <Button type="primary" icon="download" loading={!csv} onClick={this.trackCSVDownload}>
          Download
        </Button>
      </CSVLink>
    );

    const draftKings = (
      <Button
        style={{ marginLeft: "11px" }}
        type="primary"
        icon="download"
        loading={!csv}
        onClick={() => altCSVConstruction(csv)}
      >
        Download
      </Button>
    );

    return this.state.game === "fanduel" ? fanDuel : draftKings;
  };

  buildCSVData = () => {
    const { rosters, game } = this.state;
    if (rosters && rosters.length) {
      const isFanDuel = game === "fanduel";
      let headerToUser;

      if (this.state.league === "NFL") {
        headerToUser = isFanDuel ? NFL_CSV_HEADER_FD : NFL_CSV_HEADER_DK;
      } else if (this.state.league === "MLB") {
        headerToUser = isFanDuel ? MLB_CSV_HEADER_FD : MLB_CSV_HEADER_DK;
      }
      console.log(isFanDuel);
      console.log(rosters);
      return [
        headerToUser,
        ...rosters.map(r => r.lineup.players.map(p => (isFanDuel ? p.key : Number(p.key))))
      ];
    }
  };

  render() {
    const { theme } = this.props;
    const csv = this.buildCSVData();
    const { error, lastUpdated } = this.state;
    if (error) {
      throw new Error(this.state.error);
    }

    const user = this.props.user;

    return (
      <React.Fragment>
        <div className="App">
          <Row>
            <Col span={8} className="MainOptimizerControls">
              <div className="League">
                <Select defaultValue="NFL" style={{ width: 150 }} onChange={this.onLeagueChange}>
                  <Option value="NFL">NFL 11/17 Main</Option>
                </Select>
              </div>
              <div className="Game">
                <Select
                  defaultValue="DraftKings"
                  style={{ width: 150 }}
                  onChange={this.onGameChange}
                >
                  <Option value="draftkings">DraftKings</Option>
                  {/* <Option value="fanduel">FanDuel</Option> */}
                </Select>
              </div>
            </Col>
            <Col span={8}>
              <div>
                {/* <h1>BETA</h1> */}
                <h2>DFS lineups in the click of a button</h2>
                {lastUpdated && (
                  <h4 className={"LastUpdated"}>{`Last updated ${moment(
                    lastUpdated
                  ).fromNow()}`}</h4>
                )}
              </div>
            </Col>
          </Row>
          <Tabs defaultActiveKey="1" type="card" style={{ marginTop: 24 }}>
            <TabPane tab="Optimizer" key="1">
              <Button
                onClick={() => this.generate()}
                type="primary"
                icon="plus"
                loading={!this.state.loaded}
              >
                Generate
              </Button>

              {csv && this.getCSVDownloadButton(csv)}

              <Filters
                loading={!this.state.loaded}
                defaultValues={{
                  flex: this.state.flex,
                  stack: this.state.stack,
                  randomize: this.state.randomize,
                  maxPlayerExposure: this.state.maxPlayerExposure,
                  maxLineups: this.state.maxLineups,
                  lockThrough: this.state.lockThrough,
                  combo: this.state.combo
                }}
                allTeams={this.state.allTeams}
                passUp={passed => this.setState(passed)}
                lockedPlayers={this.state.track}
                bannedPlayers={this.state.banned}
                bannedTeams={this.state.bannedTeams}
                sp={this.state.sp != null ? this.state.sp : 0}
                lp={this.state.lp != null ? this.state.lp : 0}
                avg={this.state.avg != null ? this.state.avg : 0}
                ms={this.state.ms != null ? this.state.ms : 15000}
                setCombo={this.setCombo}
                pr15={this.state.pr15 || 0}
                numFilters={this.countFilters()}
                lockThroughCallback={this.lockThrough}
                setMaxLineupsCallback={this.setMaxLineups}
                setMaxPlayerExposureCallback={this.setMaxPlayerExposure}
                setRandomize={this.setRandomize}
                setStack={this.setStack}
                setFlex={this.setFlex}
                resetSettings={this.resetSettings}
              />

              <ExposureReport
                loading={!this.state.loaded}
                exposureReport={this.state.exposureReport}
              />

              {!this.state.loaded && (
                <div className="Table-results">
                  <Spin size="large" />
                </div>
              )}
              {this.state.loaded && this.state.rosters && (
                <div className="SettingsBox">
                  {this.state.userProjections && (
                    <div>
                      {this.state.userProjections.map(name => (
                        <Tag
                          color="orange"
                          // TODO - DO NOT USE NAME BUT ID HERE
                          key={name}
                        >{`Used Upload ${name}`}</Tag>
                      ))}
                    </div>
                  )}
                  {this.state.usedCustomProjections && (
                    <Tag color="red">Used Custom Projections</Tag>
                  )}
                  {this.state.showAnalysis && (
                    <ExposureReport exposureReport={this.state.exposureReport} />
                  )}
                  <Aggregate
                    rosters={this.state.rosters}
                    playerDiversityScore={this.state.playerDiversityScore}
                    medianScore={this.state.medianScore}
                    maxScore={this.state.maxScore}
                    minScore={this.state.minScore}
                  />
                  <Rosters
                    league={this.state.league}
                    rosters={this.state.rosters}
                    selectedOver={this.state.selectedOver}
                  />
                </div>
              )}
            </TabPane>
            <TabPane tab="Players" key="2">
              <PlayerOrSpin
                Component={Players}
                addLocked={this.addLocked}
                removeLocked={this.removeLocked}
                addBanned={this.addBanned}
                removeBanned={this.removeBanned}
                players={this.state.players}
                userPlayers={this.state.userPlayers}
                track={this.state.track}
                banned={this.state.banned}
                league={this.state.league}
                teams={this.state.allTeams}
                positions={this.state.allPositions}
                matchups={this.state.allMatchups}
                ctx={this}
              />
            </TabPane>
            <TabPane tab="Visualize" key="7">
              <PlayerOrSpin
                Component={DataVis}
                players={this.state.players}
                league={this.state.league}
                allTeams={this.state.allTeams}
                allPositions={this.state.allPositions}
                allMatchups={this.state.allMatchups}
              />
            </TabPane>
            <TabPane tab="Teams" key="3">
              <Teams teams={this.state.allTeams} setBannedTeams={this.setBannedTeams} />
            </TabPane>
            {user && (
              <TabPane tab="My Proj." key="6">
                <PersistentProj />
              </TabPane>
            )}
            {/* <TabPane tab="Custom Proj." key="5">
              <ProjTable
                setProjections={(customProjections, onlySource) =>
                  this.setState({ customProjections, onlySource })
                }
              />
            </TabPane> */}
          </Tabs>
        </div>
        {/* --- STYLES --- */}
        <style jsx>{`
          .App {
            padding: ${`calc(${theme.space.default} * 2 + 90px) 0 calc(${theme.space.default} * 2)`};

            margin: 0 auto;
          }
          @from-width tablet {
            .App {
              padding: ${`calc(${theme.space.default} * 2 + 70px) 0 calc(${theme.space.default} * 2)`};
            }
          }
          @from-width desktop {
            .App {
              padding: ${`calc(${theme.space.default} * 2 + 90px) 0 calc(${theme.space.default} * 2)`};
            }
          }
        `}</style>
      </React.Fragment>
    );
  }
}
Optimizer.propTypes = {
  theme: PropTypes.object.isRequired
};

export default connect(s => s)(Optimizer);
