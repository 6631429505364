import React from "react";
import Select from "react-select";
import {
  VictoryChart,
  VictoryScatter,
  VictoryTheme,
  VictoryTooltip,
  VictoryContainer,
  VictoryLabel
} from "victory";
import { Col, Row } from "antd";
import { generateSyntheticData, generateValueTitle } from "./utils";

const ALL_TEAMS = "ALL_TEAMS";
const ALL_POSITIONS = "ALL_POSITIONS";

const teamMap = new Map();

const getRandomColor = team => {
  if (teamMap.get(team)) {
    return teamMap.get(team);
  }

  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }

  teamMap.set(team, color);
  return color;
};

class DataVis extends React.Component {
  state = {
    teams: null,
    positions: null
  };

  onTeamChange = teams => {
    this.setState({ teams: teams.map(i => i.value) });
  };

  onPositionChange = positions => {
    this.setState({ positions: positions.map(i => i.value) });
  };

  onMatchupChange = matchups => {
    this.setState({ matchups: matchups.map(i => i.value) });
  };

  render() {
    const { players: allPlayers, allTeams, allPositions, allMatchups } = this.props;

    const players = allPlayers.filter(p => p.proj > 0 && p.salary > 0 && p.position !== "P");

    const allPlayerSynthetic = generateSyntheticData(players);

    const teamToColorDict = allTeams.reduce((accum, team) => {
      return { ...accum, [team]: getRandomColor(team) };
    }, {});
    const data = players
      .filter(p => {
        let reqTeam = true;
        let reqPos = true;
        let reqMatchup = true;

        if (this.state.teams && this.state.teams.length > 0) {
          reqTeam = this.state.teams.includes(p.team);
        }

        if (this.state.positions && this.state.positions.length > 0) {
          reqPos = this.state.positions.includes(p.position);
        }

        if (this.state.matchups && this.state.matchups.length > 0) {
          reqMatchup = this.state.matchups.includes(p.matchup);
        }

        let teamsReq = reqTeam && reqMatchup;
        if (
          this.state.teams &&
          this.state.teams.length > 0 &&
          this.state.matchups &&
          this.state.matchups.length > 0
        ) {
          teamsReq = reqMatchup || reqTeam;
        }

        return p.proj > 0 && p.salary > 0 && teamsReq && reqPos;
      })
      .map(p => {
        return {
          ...p,
          x: p.salary,
          y: p.proj
        };
      });

    const getLabel = d => {
      const num = parseFloat(d.value).toFixed(2);
      return `${d.name} (${num})`;
    };

    const filteredSynthetic = generateSyntheticData(data);

    const isValueSelection = filteredSynthetic.aggValue > allPlayerSynthetic.aggValue;

    const showLabels = data.length < 15;

    return (
      <div style={{ width: "60%", marginLeft: "20%", marginTop: -25 }}>
        <Row>
          <Col span={4} style={{ marginTop: 50 }}>
            <Select
              placeholder="Teams"
              defaultValue={ALL_TEAMS}
              style={{ width: 120 }}
              onChange={this.onTeamChange}
              options={allTeams.map(t => ({
                value: t,
                label: t
              }))}
              isMulti
            />
            <Select
              placeholder="Matchups"
              defaultValue="All Matchups"
              style={{ width: 120 }}
              onChange={this.onMatchupChange}
              options={allMatchups.map(t => ({
                value: t,
                label: t
              }))}
              isMulti
            />
            <Select
              placeholder="Positions"
              defaultValue={ALL_POSITIONS}
              style={{ width: 120 }}
              onChange={this.onPositionChange}
              options={allPositions.map(t => ({
                value: t,
                label: t
              }))}
              isMulti
            />
          </Col>
          <Col span={20}>
            <VictoryChart
              containerComponent={<VictoryContainer />}
              animate={{ duration: 1000 }}
              theme={VictoryTheme.material}
              domain={{
                x: [allPlayerSynthetic.minSalary, allPlayerSynthetic.maxSalary],
                y: [allPlayerSynthetic.minProj, allPlayerSynthetic.maxProj]
              }}
            >
              <VictoryLabel
                x={25}
                y={24}
                text={generateValueTitle("Selected", filteredSynthetic)}
                style={{
                  fontSize: 10,
                  ...(isValueSelection ? { fill: "green" } : {})
                }}
              />
              <VictoryLabel
                x={25}
                y={35}
                text={generateValueTitle("Overall", allPlayerSynthetic)}
                style={{ fontSize: 6 }}
              />
              <VictoryScatter
                style={{
                  data: {
                    fill: d => {
                      return teamToColorDict[d.team];
                    }
                  }
                }}
                bubbleProperty={"value"}
                maxBubbleSize={8}
                minBubbleSize={2}
                data={data}
                labels={datum => getLabel(datum)}
                labelComponent={
                  showLabels ? <VictoryLabel style={{ fontSize: 8 }} /> : <VictoryTooltip />
                }
                events={[
                  {
                    target: "data",
                    eventHandlers: {
                      onMouseOver: () => {
                        return {
                          target: "labels",
                          mutation: props => {
                            return { active: true };
                          }
                        };
                      },
                      onMouseOut: () => {
                        return [
                          {
                            target: "data",
                            mutation: () => {}
                          },
                          {
                            target: "labels",
                            mutation: () => ({ active: false })
                          }
                        ];
                      }
                    }
                  }
                ]}
              />
            </VictoryChart>
          </Col>
        </Row>
      </div>
    );
  }
}

export default DataVis;
