import React from "react";
import { notification } from "antd";
import { fetchWithToken } from "../table/network";
import "antd/dist/antd.css";
import "../App.css";

const QUERYABLE = ["avg", "ms", "lp", "sp", "league", "v", "game", "pr15"];
const DEFAULT_STATE = {
  lineup: undefined,
  league: "NFL",
  game: "draftkings",
  locked: [],
  banned: [],
  track: [],
  loaded: false,
  lastUpdated: undefined,
  searchText: "",
  filtered: false,
  filterDropdownVisible: false,
  allPlayers: [],
  userPlayers: [],
  bannedTeams: [],
  lockThrough: false,
  projectionType: 1,
  exposureReport: null,
  flex: null
};

class Optimizer extends React.Component {
  constructor(props) {
    super(props);
    this.state = DEFAULT_STATE;
  }

  resetSettings = () => {
    this.setState({
      locked: [],
      banned: [],
      bannedTeams: [],
      track: [],
      stack: undefined,
      maxLineups: 3,
      maxPlayerExposure: 3,
      sp: 0,
      lp: 0,
      avg: 0,
      ms: 15000,
      numFilters: 0,
      randomize: 0,
      searchText: "",
      lockThrough: false,
      didReset: true,
      combo: false,
      flex: null
    });
  };

  lockThrough = lockThrough => {
    this.setState({
      lockThrough
    });
  };

  setMaxLineups = maxLineups => {
    this.setState({
      maxLineups
    });
  };

  setMaxPlayerExposure = maxPlayerExposure => {
    this.setState({
      maxPlayerExposure
    });
  };

  setRandomize = randomize => {
    this.setState({ randomize });
  };

  setCombo = combo => {
    this.setState({ combo });
  };

  setStack = stack => {
    if (stack === "None") {
      this.setState({ stack: undefined });
    } else {
      this.setState({ stack });
    }
  };

  setFlex = flex => {
    this.setState({ flex });
  };

  onInputChange = e => {
    this.setState({ searchText: e.target.value });
  };

  onSearch = () => {
    const { searchText } = this.state;

    window.heap.track("Player Search", { searchText });

    const reg = new RegExp(searchText, "gi");
    this.setState({
      filterDropdownVisible: false,
      filtered: !!searchText,
      userPlayers: this.state.allPlayers
        .map(player => {
          const match = player && player.name && player.name.match(reg);
          if (!match) {
            return null;
          }
          return player;
        })
        .filter(record => !!record)
    });
  };

  async setLeague() {
    this.setState({ loaded: false });
    const { game, league, projectionType } = this.state;
    try {
      // TODO - fix this blowing away settings or find a better way to communicate
      await Promise.all([
        fetchWithToken(`players/?league=${league}&game=${game}&projection_type=${projectionType}`, {
          method: "POST",
          withJSON: false
        }).then(results => {
          results
            .json()
            .then(({ players, teams }) => {
              const reducer = (accum, player) => {
                accum.allPositions.push(player.position);
                accum.allMatchups.push(player.matchup);

                return accum;
              };

              const { allPositions, allMatchups } = players.reduce(reducer, {
                allMatchups: [],
                allPositions: []
              });
              const positions = [...new Set(allPositions)];
              const matchups = [...new Set(allMatchups)];

              this.setState({
                players,
                userPlayers: players,
                allPlayers: players,
                allTeams: teams,
                allPositions: positions,
                allMatchups: matchups
              });
            })
            .catch(error => {
              this.setState({ error });
            });
        }),
        this.generate().catch(error => {
          this.setState({ error });
        })
      ]);
    } catch (error) {
      this.setState({ error });
    }
  }

  componentDidMount() {
    this.setLeague(this.state.league);
  }

  setBannedTeams = bannedTeams => {
    this.setState({ bannedTeams });
  };

  generate() {
    console.log("Sending lineup generate");
    window.heap.track("Lineup Generate", {
      ...QUERYABLE.reduce((queryMap, key) => {
        return {
          ...queryMap,
          [key]: this.state[key]
        };
      }, {}),
      track: this.state.track,
      bannedTeams: this.state.bannedTeams
    });

    // TODO - refactor this whole thing
    this.setState({ loaded: false });
    let q = "?";
    QUERYABLE.forEach(arg => {
      if (this.state[arg]) {
        q += `${arg}=${this.state[arg]}&`;
      }
    });

    if (this.state.track.length) {
      q += `locked=${this.state.track.join(",")}&`;
    }

    if (this.state.banned && this.state.banned.length) {
      q += `banned_players=${this.state.banned.join(",")}&`;
    }

    if (this.state.bannedTeams && this.state.bannedTeams.length) {
      q += `bt=${this.state.bannedTeams.join(",")}&`;
    }

    if (this.state.lockThrough) {
      q += "lock_through=true&";
    }

    if (this.state.randomize) {
      q += `randomize=${this.state.randomize}&`;
    }

    if (this.state.maxLineups > 0) {
      q += `max_lineups=${this.state.maxLineups}&`;
    }

    if (this.state.maxPlayerExposure > 0) {
      q += `max_pe=${this.state.maxPlayerExposure}&`;
    }

    if (this.state.projectionType > 0) {
      q += `projection_type=${this.state.projectionType}&`;
    }

    if (this.state.stack) {
      q += `stack=${this.state.stack}&`;
    }

    if (this.state.combo) {
      q += `force_combo=${this.state.stack}&`;
    }

    if (this.state.flex) {
      q += `flex_position=${this.state.flex}&`;
    }

    return fetchWithToken(
      `optimize/${q}`,
      {
        method: "POST",
        withJSON: false,
        overrides: {
          body: JSON.stringify({
            customProjections: this.state.customProjections
          })
        }
      }
      // TODO - add body
    ).then(results => {
      results
        .json()
        .then(
          ({
            rosters,
            exposureReport,
            selectedOver,
            minScore,
            maxScore,
            medianScore,
            playerDiversityScore,
            userProjections,
            usedCustomProjections,
            lastUpdated
          }) => {
            if (!rosters) {
              notification.open({
                message: "No lineup available!",
                description: "Try changing the constraints on the optimizer."
              });
            }
            this.setState({
              rosters,
              exposureReport,
              minScore,
              maxScore,
              medianScore,
              playerDiversityScore,
              selectedOver,
              userProjections,
              usedCustomProjections,
              lastUpdated: lastUpdated * 1000,
              loaded: true
            });
          }
        );
    });
  }

  onLeagueChange = league => {
    this.setState({ league, state: DEFAULT_STATE }, () => this.setLeague({ league }));
  };

  onGameChange = game => {
    this.setState({ game }, () => this.setLeague({ game }));
  };

  onProjectionChange = projectionType => {
    this.setState({ projectionType }, () => this.setLeague());
  };

  addLocked = name => {
    this.setState({
      track: this.state.track.concat([name])
    });
  };

  addBanned = name => {
    this.setState(prevState => ({
      ...prevState,
      banned: prevState.banned.concat([name])
    }));
  };

  removeLocked = name => {
    const current = this.state.track;
    const index = this.state.track.indexOf(name);
    if (index > -1) {
      current.splice(index, 1);
      this.setState({ track: current });
    }
  };

  removeBanned = name => {
    const current = this.state.banned;
    const index = this.state.banned.indexOf(name);
    if (index > -1) {
      current.splice(index, 1);
      this.setState({ banned: current });
    }
  };

  countFilters = () => {
    let num = 0;
    num += this.state.track.length + this.state.banned.length + this.state.bannedTeams.length;

    num += this.state.sp ? 1 : 0;
    num += this.state.lp ? 1 : 0;
    num += this.state.avg ? 1 : 0;
    num += this.state.pr15 ? 1 : 0;

    num += this.state.ms && this.state.ms !== 15000 ? 1 : 0;

    return num;
  };

  render() {
    return "You must implement render.";
  }
}

export default Optimizer;
