import React from 'react';
import { Input, Button, Icon } from 'antd';

const alpha = prop => (a, b) => {
  if (a[prop] < b[prop]) return -1;
  if (a[prop] > b[prop]) return 1;
  return 0;
};

export const mobileColumns = nameSearchProps => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ...nameSearchProps,
  },
  {
    title: 'Salary',
    dataIndex: 'salary',
    key: 'salary',
    sorter: (a, b) => a.salary - b.salary,
  },
];

export const desktopColumns = (
  league,
  nameSearchProps,
  { teams, matchups, positions },
) => {
  const mlbOnly = [
    {
      title: 'PR15',
      dataIndex: 'pr15',
      key: 'pr15',
      sorter: (a, b) => a.pr15 - b.pr15,
    },
    {
      title: 'PR15Val',
      dataIndex: 'pr15Value',
      key: 'pr15Val',
      sorter: (a, b) => a.pr15Value - b.pr15Value,
    },
  ];

  const nflOnly = [
    {
      title: 'Avg. TS',
      dataIndex: 'averageTeamScore',
      key: 'averageTeamScore',
      sorter: (a, b) => a.averageTeamScore - b.averageTeamScore,
    },
    {
      title: 'Vegas TS',
      dataIndex: 'vegasTeamScore',
      key: 'vegasTeamScore',
      sorter: (a, b) => a.vegasTeamScore - b.vegasTeamScore,
    },
    {
      title: 'DVP',
      dataIndex: 'dvp',
      key: 'dvp',
      sorter: (a, b) => a.dvp - b.dvp,
    },
  ];

  const baseColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...nameSearchProps,
    },
    {
      title: 'Salary',
      dataIndex: 'salary',
      key: 'salary',
      sorter: (a, b) => a.salary - b.salary,
    },
    {
      title: 'Team',
      dataIndex: 'team',
      key: 'team',
      filters: teams,
    },
    {
      title: 'Matchup',
      dataIndex: 'matchup',
      key: 'matchup',
      filters: matchups,
    },
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'position',
      filters: positions,
    },
    {
      title: 'Proj.',
      dataIndex: 'proj',
      key: 'proj',
      sorter: (a, b) => a.proj - b.proj,
    },
    {
      title: 'Avg.',
      dataIndex: 'avg',
      key: 'avg',
      sorter: (a, b) => a.avg - b.avg,
    },
    {
      title: 'Val',
      dataIndex: 'value',
      key: 'value',
      sorter: (a, b) => a.value - b.value,
    },
  ];

  if (league === 'MLB') {
    return [...baseColumns, ...mlbOnly];
  }

  return [...baseColumns, ...nflOnly];
};

export const desktopLineupColumns = nameSearchProps => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ...nameSearchProps,
  },
  {
    title: 'Salary',
    dataIndex: 'salary',
    key: 'salary',
    sorter: (a, b) => a.salary - b.salary,
  },
  {
    title: 'Team',
    dataIndex: 'team',
    key: 'team',
    sorter: alpha('team'),
  },
  {
    title: 'Matchup',
    dataIndex: 'matchup',
    key: 'matchup',
    sorter: alpha('matchup'),
  },
  {
    title: 'Pos.',
    dataIndex: 'position',
    key: 'position',
    sorter: alpha('position'),
  },
  {
    title: 'Proj.',
    dataIndex: 'proj',
    key: 'proj',
    sorter: (a, b) => a.proj - b.proj,
  },
  {
    title: 'Avg.',
    dataIndex: 'avg',
    key: 'avg',
    sorter: (a, b) => a.avg - b.avg,
  },
];

export const generateNameFilterColumns = (fn, allProps) => {
  const { ctx } = allProps;
  return fn(
    ctx.state.league,
    {
      filterDropdown: (
        <div className="custom-filter-dropdown">
          <Input
            ref={ele => (ctx.searchInput = ele)}
            placeholder="Search name"
            value={ctx.state.searchText}
            onChange={ctx.onInputChange}
            onPressEnter={ctx.onSearch}
          />
          <Button type="primary" onClick={ctx.onSearch}>
            Search
          </Button>
        </div>
      ),
      filterIcon: (
        <Icon
          type="search"
          style={{ color: ctx.state.filtered ? '#108ee9' : '#aaa' }}
        />
      ),
      filterDropdownVisible: ctx.state.filterDropdownVisible,
      onFilterDropdownVisibleChange: visible => {
        ctx.setState(
          {
            filterDropdownVisible: visible,
          },
          () => ctx.searchInput && ctx.searchInput.focus(),
        );
      },
    },
    allProps,
  );
};
