import React from "react";
import { Spin } from "antd";

export default ({ players, Component, ...props }) => {
  if (!players) {
    return <Spin />;
  }

  return <Component players={players} {...props} />;
};
