import React from "react";
import PropTypes from "prop-types";
import "prismjs/themes/prism-okaidia.css";

import Responsive from "react-responsive";
import DesktopOptimizer from "../../components/Optimizer/optimizers/DesktopOptimizer";

const MOBILE_BREAK = 735;
const Optimizer = props => {
  const { theme } = props;

  const Mobile = props => <Responsive {...props} maxWidth={MOBILE_BREAK} />;
  const Desktop = props => <Responsive {...props} minWidth={MOBILE_BREAK} />;
  return (
    <React.Fragment>
      <Mobile>
        <DesktopOptimizer theme={theme} />
      </Mobile>
      <Desktop>
        <DesktopOptimizer theme={theme} />
      </Desktop>
    </React.Fragment>
  );
};

Optimizer.propTypes = {
  theme: PropTypes.object.isRequired
};

export default Optimizer;
