import React, { Component } from "react";
import Select from "react-select";
import { Row } from "antd";
import MiniControl from "./MiniControl";

class Settings extends Component {
  toggleLocking = lockThrough => {
    window.heap.track("Toggled Persistent Lock", { lockThrough });
    this.props.lockThroughCallback(lockThrough);
  };

  setMaxLineups = maxLineups => {
    window.heap.track("Set Max Lineups", { maxLineups });
    this.props.setMaxLineupsCallback(maxLineups);
  };

  setMaxPlayerExposure = maxPlayerExposure => {
    window.heap.track("Set Max Player Exposure", { maxPlayerExposure });
    this.props.setMaxPlayerExposureCallback(maxPlayerExposure);
  };

  toggleRandomize = randomize => {
    window.heap.track("Toggled Randomize", { randomize });
    this.props.setRandomize(randomize);
  };

  toggleStack = stack => {
    window.heap.track("Stack Set", { stack: stack.value });
    this.props.setStack(stack.value);
  };

  toggleFlex = flex => {
    window.heap.track("Stack Flex", { flex: flex.value });
    this.props.setFlex(flex.value);
  };

  toggleCombo = combo => {
    window.heap.track("Combo set", { combo });
    this.props.setCombo(combo);
  };

  render() {
    const {
      stack,
      flex,
      combo,
      randomize,
      maxPlayerExposure,
      maxLineups,
      lockThrough
    } = this.props.defaultValues;

    let drop;
    if (stack) {
      drop = { label: stack, value: stack };
    }

    let flexDrop;
    if (flex) {
      flexDrop = { label: flex, value: flex };
    }

    return (
      <Row>
        <p className={"Locked"}>Stacking</p>
        <Select
          placeholder="Stack Team"
          defaultValue={drop || "None"}
          value={drop || "None"}
          style={{ width: 120 }}
          onChange={this.toggleStack}
          options={[
            { value: "None", label: "None" },
            ...this.props.allTeams.map(t => ({
              value: t,
              label: t
            }))
          ]}
        />
        {/*<Select*/}
        {/*placeholder="Stack Type"*/}
        {/*defaultValue={drop || 'None'}*/}
        {/*value={drop || 'None'}*/}
        {/*style={{ width: 120 }}*/}
        {/*onChange={this.toggleStack}*/}
        {/*options={[*/}
        {/*{ value: 'None', label: 'None' },*/}
        {/*{ value: 'None', label: 'Stack QB+2WR' },*/}
        {/*{ value: 'None', label: 'Stack QB+1WR' },*/}
        {/*{ value: 'None', label: 'Stack QB+3WR' },*/}
        {/*{ value: 'None', label: 'Stack QB+1RB+1WR' },*/}
        {/*{ value: 'None', label: 'Stack QB+1RB+2WR' },*/}
        {/*]}*/}
        {/*/>*/}
        <div style={{ marginTop: 24 }}>
          <p className={"Locked"}>Flexing</p>
          <Select
            placeholder="Flex Type"
            defaultValue={flexDrop || "None"}
            value={flexDrop || "None"}
            style={{ width: 120 }}
            onChange={this.toggleFlex}
            options={[
              { value: "None", label: "None" },
              { value: "no_double_te", label: "No Double TE" },
              { value: "RB", label: "RB" },
              { value: "WR", label: "WR" },
              { value: "TE", label: "TE" }
            ]}
          />
        </div>

        <div style={{ marginTop: 24 }}>
          <p className={"Locked"}>Lineup Exposure</p>
          <MiniControl
            defaultValue={maxLineups || 3}
            value={maxLineups || 3}
            inputType="number"
            title="Max Lineups"
            description="The maximum number of lineups to generate"
            onChange={(...args) => this.setMaxLineups(...args)}
            min={1}
            max={150}
          />
          <MiniControl
            defaultValue={maxPlayerExposure || 3}
            value={maxPlayerExposure || 3}
            inputType="number"
            title="Max Player Exposure"
            description="The maximum number of lineups one player can be in. Overrides locking."
            onChange={(...args) => this.setMaxPlayerExposure(...args)}
            min={1}
            max={50}
          />
          <MiniControl
            defaultValue={randomize != null ? randomize : 0}
            value={randomize != null ? randomize : 0}
            inputType="number"
            title="Randomize"
            description="Randomize projections by the selected percentage (direction is random)."
            onChange={(...args) => this.toggleRandomize(...args)}
            min={0}
            max={50}
          />
          <MiniControl
            defaultValue={lockThrough}
            value={lockThrough}
            inputType="switch"
            title="Persistent Lock"
            description="Lock selected players in all generated lineups (default is to only lock in one)."
            onChange={(...args) => this.toggleLocking(...args)}
          />
          <MiniControl
            defaultValue={combo}
            value={combo}
            inputType="switch"
            title="Force QB / WR Combo"
            description="Always pair a QB with WR on same team."
            onChange={(...args) => this.toggleCombo(...args)}
          />
        </div>
      </Row>
    );
  }
}

export default Settings;
