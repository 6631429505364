const isAuthenticated = user => {
  return {
    user,
    // TODO - get projections on log-in
    type: "AUTHENTICATED",
    authenticated: true
  };
};

const isReturning = (user, projections, token) => {
  return {
    user,
    projections,
    token,
    type: "USER",
    authenticated: true
  };
};

const reducer = (state = { authenticated: false, token: null }, action) => {
  // TODO - implement some type of analytics
  if (action.type === "AUTHENTICATED") {
    return {
      ...state,
      ...action
    };
  }
  if (action.type === "USER") {
    return {
      ...state,
      ...action
    };
  }

  return state;
};

export { reducer, isAuthenticated, isReturning };
