const fetchWithToken = async (
  path,
  { method = "GET", withJSON = true, headerOverrides = {}, overrides = {} } = {}
) => {
  const token = localStorage.getItem("dfast_val");
  const withAuth = token ? { Authorization: `Token ${token}` } : {};
  const res = await fetch(`${process.env.REACT_APP_DRAFTFAST_API}/${path}`, {
    method,
    ...overrides,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...withAuth,
      ...headerOverrides
    }
  });

  if (res.status === 401 && token) {
    localStorage.removeItem("dfast_val");
    window.location.reload();
  }

  return withJSON ? res.json() : res;
};

export { fetchWithToken };
