import React from "react";
import { fetchWithToken } from "./network";
import { notification, Table, Checkbox, Spin } from "antd";

const formatNewDate = () => {
  const d = new Date();
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) {
    month = "0" + month;
  }
  if (day.length < 2) {
    day = "0" + day;
  }

  return [year, month, day].join("-");
};

// https://gist.github.com/AshikNesin/e44b1950f6a24cfcd85330ffc1713513/

class PersistentProj extends React.Component {
  state = {
    file: null,
    uploads: null,
    loading: false
  };

  lookupUploads = () => fetchWithToken("api/uploads");

  componentDidMount() {
    this.lookupUploads().then(uploads => {
      this.setState({ uploads });
    });
  }

  onFormSubmit = e => {
    this.setState({ loading: true });

    e.preventDefault();
    const formData = new FormData();
    formData.append("file", this.state.file);

    // fetchWithToken was not working here
    fetch(`${process.env.REACT_APP_DRAFTFAST_API}/api/uploads/upload_projection/`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Token ${localStorage.getItem("dfast_val")}`
      }
    }).then(async res => {
      const json = await res.json();

      if (json.errors.length > 0) {
        notification.open({
          message: "Issue uploading file.",
          description: `${json.errors.reduce(
            (accum, curr) => (accum ? accum + ". " + curr : accum + curr),
            ""
          )}`
        });
      } else {
        notification.open({
          message: `Uploaded ${json.filename}`,
          description: `Your next optimizer run for ${json.game} will use these projections.`
        });

        this.setState(prevState => {
          return {
            ...prevState,
            uploads: [
              {
                is_enabled: true,
                s3_file_name: json.filename,
                date_uploaded: formatNewDate(),
                game: json.filename.toLowerCase().includes("dk") ? "DratKings" : "FanDuel",
                id: json.id
              },
              ...prevState.uploads
            ]
          };
        });
      }

      this.setState({ loading: false });
    });
  };

  onChange = e => {
    this.setState({ file: e.target.files[0] });
  };

  render() {
    const columns = [
      {
        title: "Enabled?",
        dataIndex: "is_enabled",
        key: "is_enabled",
        render: (a, upload) => {
          return (
            <Checkbox
              key={upload.id}
              checked={upload.is_enabled}
              onChange={e => {
                const status = e.target.checked;
                this.setState(prevState => {
                  const newUploads = prevState.uploads.map(u => {
                    if (u.id === upload.id) {
                      return { ...u, is_enabled: status };
                    }

                    return u;
                  });

                  fetchWithToken("api/uploads/toggle_upload/", {
                    method: "POST",
                    overrides: {
                      body: JSON.stringify({
                        id: upload.id,
                        is_enabled: status
                      })
                    }
                  });

                  return {
                    ...prevState,
                    uploads: newUploads
                  };
                });
              }}
            />
          );
        }
      },
      {
        title: "Filename",
        dataIndex: "s3_file_name",
        key: "s3_file_name"
      },
      {
        title: "Date Uploaded",
        dataIndex: "date_uploaded",
        key: "date_uploaded"
      },
      {
        title: "Game",
        dataIndex: "game",
        key: "game"
      }
    ];

    return (
      <div>
        {!this.state.loading && (
          <form onSubmit={this.onFormSubmit}>
            <input type="file" onChange={this.onChange} />
            <button type="submit" disabled={!this.state.file}>
              Upload
            </button>
          </form>
        )}
        {this.state.loading && <Spin />}
        <div style={{ width: "80%", marginTop: 24, marginLeft: "10%" }}>
          {this.state.uploads && (
            <Table
              size="small"
              dataSource={this.state.uploads.map(u => ({ ...u, key: u.id }))}
              columns={columns}
            />
          )}
        </div>
      </div>
    );
  }
}

export default PersistentProj;
