import React, { useEffect, useState, Component } from "react";

import { ThemeContext } from "../../layouts";
import { graphql } from "gatsby";
import Seo from "../../components/Seo";
import { navigate } from "gatsby";
import { checkSubscribedToNFL } from "../../utils/helpers";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { reducer } from "../../components/Optimizer/Redux/util";
import Optimizer from "../../components/Optimizer/Optimizer";
import { isAuthenticated, getUserInfo, checkUserIsAdmin } from "../../utils/auth";

const IS_DEV = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

const CorePicksPage = props => {
  const [isSubscribedNLF, setSubscribedNLF] = useState(null);

  let store;
  if (IS_DEV) {
    store = createStore(
      reducer,
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );
  } else {
    store = createStore(reducer);
  }

  useEffect(() => {
    if (!isAuthenticated()) {
      setSubscribedNLF(false);
    }

    const profile = typeof window !== "undefined" ? getUserInfo() : "";

    const checkSubscription = async () => {
      if (checkUserIsAdmin()) {
        setSubscribedNLF(true);
      } else {
        const res = await checkSubscribedToNFL(profile.email);
        if (res === 0) {
          setSubscribedNLF(false);
        } else {
          setSubscribedNLF(true);
        }
      }
    };
    checkSubscription();
  });

  if (isSubscribedNLF === null) {
    return null;
  } else if (!isSubscribedNLF) {
    navigate("/membership");
    return null;
  } else {
    const {
      data: {
        coreplay: {
          data: {
            content: { html: html },
            title: { text: title }
          }
        },
        site: {
          siteMetadata: { facebook }
        }
      }
    } = props;
    const page = {
      html,
      frontmatter: { title }
    };

    return (
      <React.Fragment>
        <Provider store={store}>
          <ThemeContext.Consumer>{theme => <Optimizer theme={theme} />}</ThemeContext.Consumer>
          <Seo facebook={facebook} />
        </Provider>
      </React.Fragment>
    );
  }
};

export default CorePicksPage;

export const query = graphql`
  query getOptimizer {
    coreplay: prismicCorePlay {
      data {
        content {
          html
        }
        title {
          text
        }
      }
    }
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
  }
`;
