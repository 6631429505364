import React from "react";
import PropTypes from "prop-types";
import { Tag } from "antd";

const Aggregate = ({ rosters, playerDiversityScore, medianScore, maxScore, minScore }) => (
  <div className="SettingsBox">
    <Tag color="blue">{`${rosters.length} Lineups`}</Tag>
    <Tag color="blue">{`Diversity ${playerDiversityScore}`}</Tag>
    <Tag color="blue">{`Median ${medianScore}`}</Tag>
    <Tag color="blue">{`Max ${maxScore}`}</Tag>
    <Tag color="blue">{`Min ${minScore}`}</Tag>
  </div>
);

Aggregate.propTypes = {
  rosters: PropTypes.array.isRequired,
  playerDiversityScore: PropTypes.number.isRequired,
  medianScore: PropTypes.number.isRequired,
  maxScore: PropTypes.number.isRequired,
  minScore: PropTypes.number.isRequired
};

export default Aggregate;
