import React, { Component } from "react";
import { Checkbox, Row, Col } from "antd";

class Teams extends Component {
  onChange = bannedTeams => {
    this.props.setBannedTeams(bannedTeams);

    window.heap.track("Team Banning", { bannedTeams });
  };

  render() {
    // TODO - How are these checkmarks persisted?
    return (
      <div>
        <h4>Banned Teams</h4>
        <Checkbox.Group className="Teams" onChange={this.onChange}>
          <Row>
            {this.props.teams.map(t => (
              <Col key={t} span={8}>
                <Checkbox value={t}>{t}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </div>
    );
  }
}

export default Teams;
