import React from "react";
import PropTypes from "prop-types";
import { Row, Col, InputNumber, Switch } from "antd";

const MiniControl = ({
  inputType,
  onChange,
  title,
  description,
  defaultValue,
  value,
  min,
  max
}) => (
  <Row className="Settings">
    <Col span={4}>
      {inputType === "number" && (
        <InputNumber
          min={min}
          max={max}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
        />
      )}
      {inputType === "switch" && (
        <Switch defaultChecked={defaultValue} checked={value} onChange={onChange} />
      )}
    </Col>
    <Col span={7}>
      <p className="SettingTitle">{title}</p>
    </Col>
    <Col span={14}>
      <p>{description}</p>
    </Col>
  </Row>
);

MiniControl.propTypes = {
  inputType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number
};

export default MiniControl;
