import React from "react";
import { Tag } from "antd";

export default props => (
  <div>
    <div>
      {props.lockedPlayers.length > 0 && (
        <div className="FilterText">
          <p className={"Locked"}>Locked Players</p>
          {props.lockedPlayers.map(p => (
            <Tag key={p} color={"green"}>
              {p}
            </Tag>
          ))}
        </div>
      )}
      {props.lockedPlayers.length === 0 && (
        <div className="FilterText">
          <p className={"Locked"}>Locked Players</p>
          <p>
            You haven't locked any players; to lock players, click the Players tab and check a box
            or boxes.
          </p>
        </div>
      )}
    </div>
    <div>
      {props.bannedPlayers.length > 0 && (
        <div className="FilterText">
          <p className={"Locked"}>Banned Players</p>
          {props.bannedPlayers.map(p => (
            <Tag key={p} color={"orange"}>
              {p}
            </Tag>
          ))}
        </div>
      )}
      {props.bannedPlayers.length === 0 && (
        <div className="FilterText">
          <p className={"Locked"}>Banned Players</p>
          <p>
            You haven't banned any players; to lock players, click the Players tab and check a box
            or boxes.
          </p>
        </div>
      )}
    </div>
    <div>
      {props.bannedTeams.length > 0 && (
        <div className="FilterText">
          <p className={"Locked"}>Banned Teams</p>
          {props.bannedTeams.map(p => (
            <Tag key={p} color={"orange"}>
              {p}
            </Tag>
          ))}
        </div>
      )}
      {props.bannedTeams.length === 0 && (
        <div className="FilterText">
          <p className={"Locked"}>Banned Teams</p>
          <p>
            You haven't banned any teams; to ban a team, click the Teams tab and check a box or
            boxes under "Banned Teams".
          </p>
        </div>
      )}
    </div>
  </div>
);
