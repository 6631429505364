import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import Modal from "react-modal";
import DecimalStep from "./DecimalStep";
import LockBan from "./LockBan";
import Settings from "./Settings";

// Modal.setAppElement("#root");
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fafafa",
    height: "90%"
  }
};

class Filters extends Component {
  state = {
    modalIsOpen: false,
    reset: null
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  render() {
    const { modalIsOpen } = this.state;

    if (!modalIsOpen) {
      return (
        <Button
          style={{ marginLeft: 8, marginTop: 24 }}
          onClick={() => this.setState({ modalIsOpen: true })}
          icon="setting"
          type="primary"
          loading={this.props.loading}
        >
          {this.props.numFilters ? `Settings (${this.props.numFilters})` : "Settings"}
        </Button>
      );
    }

    return (
      <div className="Filter-container">
        <Modal
          isOpen={this.state.modalIsOpen}
          // onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Settings"
        >
          <h2>Optimizer Settings</h2>
          <Settings
            defaultValues={this.props.defaultValues}
            allTeams={this.props.allTeams}
            lockThroughCallback={this.props.lockThroughCallback}
            setMaxLineupsCallback={this.props.setMaxLineupsCallback}
            setMaxPlayerExposureCallback={this.props.setMaxPlayerExposureCallback}
            setRandomize={this.props.setRandomize}
            setStack={this.props.setStack}
            setCombo={this.props.setCombo}
            setFlex={this.props.setFlex}
          />
          <LockBan {...this.props} />
          <div style={{ marginTop: 24 }}>
            <p className={"Locked"}>Player Filters</p>
            <DecimalStep
              filter={"Min. Proj."}
              min={0}
              max={70}
              defaultValue={this.props.lp}
              onChange={lp => this.props.passUp({ lp })}
            />
            <DecimalStep
              filter={"Min. Avg."}
              min={0}
              max={70}
              defaultValue={this.props.avg}
              onChange={avg => this.props.passUp({ avg })}
            />
            <DecimalStep
              filter={"Max. Salary"}
              min={0}
              max={15000}
              defaultValue={this.props.ms}
              onChange={ms => this.props.passUp({ ms })}
            />
            <DecimalStep
              filter={"Min. Salary"}
              min={0}
              max={15000}
              defaultValue={this.props.sp}
              onChange={sp => this.props.passUp({ sp })}
            />
          </div>
          <div style={{ marginTop: 24 }}>
            <p className={"Locked"}>Reset Settings</p>
            <Button
              style={{ width: "100%" }}
              onClick={() => {
                this.props.resetSettings();
                this.setState({ reset: true });
              }}
              icon="reload"
              loading={this.props.loading}
            >
              Reset
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

Filters.propTypes = {
  passUp: PropTypes.func.isRequired,
  lp: PropTypes.number.isRequired,
  avg: PropTypes.number.isRequired,
  ms: PropTypes.number.isRequired,
  sp: PropTypes.number.isRequired,
  lockedPlayers: PropTypes.array.isRequired,
  bannedPlayers: PropTypes.array.isRequired,
  bannedTeams: PropTypes.array.isRequired,
  numFilters: PropTypes.number.isRequired
};

export default Filters;
