import React from "react";
import { Icon, Table } from "antd";
import LineupFeedback from "../LineupMetadata/LineupFeedback";
import { desktopLineupColumns } from "./columns";

const Rosters = ({ league, rosters, selectedOver }) => {
  const mlbOnly = {
    title: "Trend",
    dataIndex: "trend",
    key: "trend",
    render: (a, player) => {
      if (player.pr15 > 3) {
        return (
          <Icon
            type={"up-square"}
            style={{
              color: "green",
              paddingLeft: "21%"
            }}
          />
        );
      }

      if (player.pr15 < 0) {
        return (
          <Icon
            type={"down-square"}
            style={{
              color: "red",
              paddingLeft: "21%"
            }}
          />
        );
      }
    }
  };

  const locked = {
    title: "Locked?",
    dataIndex: "locked",
    key: "locked",
    render: (a, player) => {
      if (player.locked) {
        return (
          <Icon
            type={"lock"}
            style={{
              paddingLeft: "21%"
            }}
          />
        );
      }
    }
  };

  const extra = league === "MLB" ? [mlbOnly, locked] : [locked];

  // console.log(rosters[0].lineup.players);
  // console.log(desktopLineupColumns({}).concat(extra));

  return rosters.map((r, i) => (
    <div key={r.proj} className="Table-results">
      <LineupFeedback
        name={`Lineup ${i + 1}`}
        projected={r.proj}
        optimism={r.optimism}
        spent={r.salary}
        selectedOver={selectedOver}
      />
      <Table
        size="middle"
        dataSource={r.lineup.players || []}
        columns={desktopLineupColumns({}).concat(extra)}
        pagination={false}
      />
    </div>
  ));
};
export default Rosters;
