import React, { Component } from "react";
import { Button, Row } from "antd";
import Modal from "react-modal";
import MiniExposure from "./MiniExposure";

// Modal.setAppElement("#root");
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-33%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fafafa",
    height: "90%"
  }
};

const mapPlayer = p => ({ x: p.name, y: p.lineupPct });
const getPositionData = (players, pos) => {
  return players
    .filter(p => p.position.includes(pos))
    .map(mapPlayer)
    .reverse();
};

class ExposureReport extends Component {
  state = {
    modalIsOpen: false
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  render() {
    const { modalIsOpen } = this.state;
    const { loading } = this.props;

    if (!modalIsOpen) {
      return (
        <Button
          style={{ marginLeft: "10px" }}
          onClick={() => this.setState({ modalIsOpen: true })}
          type="primary"
          icon="bar-chart"
          loading={loading}
        >
          Analyze
        </Button>
      );
    }

    const {
      exposureReport: { players, matchups, teams }
    } = this.props;

    const trimmed = players.slice(0, 10);
    trimmed.reverse();

    const playerData = trimmed.map(mapPlayer);
    const qbData = getPositionData(players, "QB");
    const rbData = getPositionData(players, "RB");
    const wrData = getPositionData(players, "WR");
    const teData = getPositionData(players, "TE");
    const defData = getPositionData(players, "D");

    const trimmedB = matchups.slice(0, 10);
    trimmedB.reverse();

    const matchupData = trimmedB.map(p => ({ x: p.name, y: p.total_pct }));

    const trimmedC = teams.slice(0, 10);
    trimmedC.reverse();
    const teamData = trimmedC.map(p => ({ x: p.name, y: p.total_pct }));

    return (
      <Modal
        isOpen={this.state.modalIsOpen}
        // onAfterOpen={this.afterOpenModal}
        onRequestClose={this.closeModal}
        style={customStyles}
        contentLabel="Settings"
      >
        <div>
          <Row>
            <MiniExposure data={playerData} title="Players" />
            <MiniExposure data={matchupData} title="Matchup" />
          </Row>
          <Row>
            <MiniExposure data={teamData} title="Team" />
            <MiniExposure data={qbData} title="QB" />
          </Row>
          <Row>
            <MiniExposure data={rbData} title="RB" />
            <MiniExposure data={wrData} title="WR" />
          </Row>
          <Row>
            <MiniExposure data={teData} title="TE" />
            <MiniExposure data={defData} title="D" />
          </Row>
        </div>
      </Modal>
    );
  }
}
export default ExposureReport;
