import React from "react";
import { VictoryBar } from "victory";
import { Col } from "antd";

const MiniExposure = ({ data, title }) => (
  <Col span={11}>
    <div
      style={{
        height: 170,
        marginTop: 24,
        marginBottom: 24,
        textAlign: "center"
      }}
    >
      <p>{title}</p>
      <VictoryBar
        style={{
          labels: { fill: "black", fontSize: 16 },
          data: { fill: "#3D9970" }
        }}
        labels={datum => `${datum.x} (${datum.y})`}
        barRatio={1}
        cornerRadius={1}
        horizontal
        data={data}
      />
    </div>
  </Col>
);

export default MiniExposure;
