import React, { Component } from "react";
import { Slider, InputNumber, Row, Col } from "antd";

class DecimalStep extends Component {
  state = {
    inputValue: 0
  };
  onChange = value => {
    this.setState({
      inputValue: value
    });
    this.props.onChange && this.props.onChange(value);
  };
  render() {
    return (
      <Row>
        <Col span={4}>
          <p>{this.props.filter}</p>
        </Col>
        <Col span={12}>
          <Slider
            min={this.props.min}
            max={this.props.max}
            onChange={this.onChange}
            defaultValue={this.props.defaultValue}
            value={this.props.defaultValue}
            step={0.01}
          />
        </Col>
        <Col span={4}>
          <InputNumber
            min={this.props.min}
            max={this.props.max}
            defaultValue={this.props.defaultValue}
            style={{ marginLeft: 16 }}
            step={1}
            value={this.props.defaultValue}
            onChange={this.onChange}
          />
        </Col>
      </Row>
    );
  }
}

export default DecimalStep;
