import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, Checkbox } from "antd";
import { desktopColumns, generateNameFilterColumns } from "./columns";

class Players extends Component {
  static propTypes = {
    addLocked: PropTypes.func.isRequired,
    removeLocked: PropTypes.func.isRequired,
    addBanned: PropTypes.func.isRequired,
    removeBanned: PropTypes.func.isRequired,
    players: PropTypes.array.isRequired,
    track: PropTypes.array.isRequired,
    banned: PropTypes.array.isRequired
  };

  onTableChange = (pagination, filters) => {
    const { team = [], position = [], matchup = [] } = filters;

    this.props.ctx.setState(prevState => {
      const prevPlayers = this.props.players;
      const newPlayers = prevPlayers.filter(p => {
        let meetsTeamFilter = true;
        let meetsPosFilter = true;
        let meetsMatchFilter = true;

        if (team.length > 0) {
          meetsTeamFilter = team.includes(p.team);
        }

        if (position.length > 0) {
          meetsPosFilter = position.includes(p.position);
        }

        if (matchup.length > 0) {
          meetsMatchFilter = matchup.includes(p.matchup);
        }

        return meetsTeamFilter && meetsPosFilter && meetsMatchFilter;
      });

      return {
        ...prevState,
        userPlayers: newPlayers
      };
    });
  };

  render() {
    const {
      addLocked,
      track,
      userPlayers,
      teams,
      positions,
      matchups,
      removeLocked,
      banned,
      addBanned,
      removeBanned,
      ctx
    } = this.props;

    const mapToFormat = t => ({ text: t, value: t });

    return (
      <Table
        className="PlayerTable"
        size="medium"
        onChange={this.onTableChange}
        dataSource={userPlayers || []}
        columns={generateNameFilterColumns(desktopColumns, {
          ctx,
          teams: teams.map(mapToFormat),
          positions: positions.map(mapToFormat),
          matchups: matchups.map(mapToFormat)
        }).concat([
          {
            title: "Locked?",
            dataIndex: "locked",
            key: "locked",
            render: (a, player) => {
              return (
                <Checkbox
                  checked={track.includes(player.name)}
                  onChange={e => {
                    if (e.target.checked) {
                      addLocked(player.name);

                      window.heap.track("Player Lock", {
                        player: player.name
                      });
                    } else {
                      removeLocked(player.name);

                      window.heap.track("Player Remove", {
                        player: player.name
                      });
                    }
                  }}
                />
              );
            }
          },
          {
            title: "Banned?",
            dataIndex: "banned",
            key: "banned",
            render: (a, player) => {
              return (
                <Checkbox
                  checked={banned.includes(player.name)}
                  onChange={e => {
                    if (e.target.checked) {
                      addBanned(player.name);

                      window.heap.track("Banned Add", {
                        player: player.name
                      });
                    } else {
                      removeBanned(player.name);

                      window.heap.track("Banned Remove", {
                        player: player.name
                      });
                    }
                  }}
                />
              );
            }
          }
        ])}
        pagination={true}
      />
    );
  }
}

export default Players;
