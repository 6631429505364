import React, { Component } from "react";
import { Tag } from "antd";

const SELECTED_MIN_GOOD = 100;
const PROJECTED_MIN_OKAY = 245;
const OPTIMISTIC = 20;

class LineupFeedback extends Component {
  getSelectedOver(selectedOver) {
    return selectedOver < SELECTED_MIN_GOOD ? (
      <Tag color="red">{`Small Selection (${selectedOver})`}</Tag>
    ) : (
      <Tag color="green">{`Large Selection (${selectedOver})`}</Tag>
    );
  }

  getProjected(projected) {
    if (true) {
      return <Tag color="green">{`Projected ${projected}`}</Tag>;
    } else if (projected > PROJECTED_MIN_OKAY) {
      return <Tag color="yellow">{`Medium Projected (${projected})`}</Tag>;
    }

    return <Tag color="red">{`Low Projected (${projected})`}</Tag>;
  }

  getWellSpent(spent) {
    // TODO - figure out something
    return false ? (
      <Tag color="red">{`Poorly Spent (${spent})`}</Tag>
    ) : (
      <Tag color="green">{`Spent $${spent}`}</Tag>
    );
  }

  getOptimism(optimism) {
    return optimism > OPTIMISTIC ? (
      <Tag color="red">{`Optimistic (${optimism})`}</Tag>
    ) : (
      <Tag color="green">{`In Line (${optimism})`}</Tag>
    );
  }
  render() {
    return (
      <div className={"Tags"}>
        <Tag color="green">{this.props.name}</Tag>
        {this.getProjected(this.props.projected)}
        {this.getWellSpent(this.props.spent)}
      </div>
    );
  }
}

export default LineupFeedback;
